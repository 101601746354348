<template>
    <div>
        <transition name="slide">
        <!--<sidebar></sidebar>-->
            <router-view></router-view>
        </transition>
    </div>
</template>

<style>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .5s
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0
    }
</style>
<script>
import { mapGetters, mapActions } from 'vuex'

import Headerbar from '@/components/Header.vue'
import Sidebar from '@/components/Sidebar.vue'
import Dropdown from '@/ui/Dropdown.vue'
import {DARK_PURPLE_LOADER_COLOR} from "@/common/constants";

export default ({
  'name': 'AnalyticsMain',
  'components': {
    Headerbar,
    Sidebar,
    Dropdown
  },
  data: function () {
    return {
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      currentTab: 'tab1'
    }
  },
  mounted () {
    $(document).ready(function () {
      let self = this
      // publications drag and drop click events
      setTimeout(function () {
        $('.analytic_datepicker').daterangepicker({
          opens: 'left',
          autoUpdateInput: false,
          maxDate: moment(),
          ranges: {
            'All Time': '',
            '24 hours': [moment.utc().subtract(24, 'hours'), moment.utc()],
            '48 hours': [moment.utc().subtract(48, 'hours'), moment.utc()],
            '7 days': [moment.utc().subtract(7, 'day'), moment.utc()],
            '30 Days': [moment.utc().subtract(30, 'days'), moment.utc()],
            'This Month': [moment.utc().startOf('month'), moment.utc().endOf('month')],
            'Last Month': [moment.utc().subtract(1, 'month').startOf('month'), moment.utc().subtract(1, 'month').endOf('month')]
          },
          timePicker: true
        })
      }, 500)
    })
  },

  created () {

  },

  computed: {

    ...mapGetters([

    ])

  },

  methods: {

    ...mapActions([

    ])

  }
})
</script>
